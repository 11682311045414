<template ref="dialog">
  <!--  <div class="dialog bg-theme-1">-->
  <!--    <h1 class="text-3xl font-poppins-medium text-white">Contact Sale</h1>-->
  <!--  </div>-->
  <transition name="modal" v-if="dialog">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <validation-observer v-slot="{ invalid }">
        <div class="modal-container bg-theme-1 px-11 py-8 relative">
          <a @click="dialog = false" class="close h-8 w-8 bg-theme-11 flex -right-2 -top-2 absolute justify-around rounded-md drop-shadow-md cursor-pointer hover:drop-shadow-md">
            <svg class="place-self-center" fill="#ffffff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="20px" height="20px"><path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"/></svg>
          </a>
          <div class="elasticball" v-if="loading">
            <div class="elasticball__ball"><i></i></div>
          </div>
          <div v-if="!loading">
            <div class="modal-header">
              <h1 class="text-xl md:text-3xl font-poppins-bold text-white">Contact Sales</h1>
            </div>
            <div class="modal-body">
              <div>
                <div class="lg:flex gap-x-5">
                  <div class="group relative w-full">
                    <validation-provider rules='required' :custom-messages="{ required: 'first name field can not be empty!' }" v-slot="{ errors }">
                      <label for="1" class="block w-full pb-1 text-sm font-medium transition-all duration-200 ease-in-out group-focus-within:text-blue-400">First name
                        <span class="valid text-red">*</span></label>
                      <input v-model="form.first_name" name="firstname" id="1" type="text" class="peer h-10 w-full rounded-md px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:drop-shadow-lg"/>
                      <span class='error block left-2 top-full mt-1 text-red-600' style="font-size: 10px">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="group relative w-full">
                    <validation-provider rules='required' :custom-messages="{ required: 'last name field can not be empty!' }" v-slot="{ errors }">
                      <label for="2" class="block w-full pb-1 text-sm font-medium transition-all duration-200 ease-in-out group-focus-within:text-blue-400">Last name
                        <span class="valid text-red">*</span></label>
                      <input id="2" name="lastname" v-model="form.last_name" type="text" class="peer h-10 w-full rounded-md px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:drop-shadow-lg"/>
                      <span class='error block left-2 top-full mt-1 text-red-600' style="font-size: 10px">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
                <div class="group relative w-full mt-2 md:mt-5">
                  <validation-provider rules='required' :custom-messages="{ required: 'company name field can not be empty!' }" v-slot="{ errors }">
                    <label for="3" class="block w-full pb-1 text-sm font-medium transition-all duration-200 ease-in-out group-focus-within:text-blue-400">Company name
                      <span class="valid text-red">*</span></label>
                    <input id="3" name="companyName" v-model="form.company_name" type="text" class="peer h-10 w-full rounded-md px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:drop-shadow-lg"/>
                    <span class='error block left-2 top-full mt-1 text-red-600' style="font-size: 10px">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="group relative w-full mt-2 md:mt-5">
                  <validation-provider rules='required|email' :custom-messages="{ required: 'Please use a valid email address!' }" v-slot="{ errors }">
                    <label for="4" class="block w-full pb-1 text-sm font-medium transition-all duration-200 ease-in-out group-focus-within:text-blue-400">Company email
                      <span class="valid text-red">*</span></label>
                    <input id="4" name="companyEmail" v-model="form.company_email" type="email" class="peer h-10 w-full rounded-md px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:drop-shadow-lg"/>
                    <span class='error block left-2 top-full mt-1 text-red-600' style="font-size: 10px">{{ errors[0] }}</span>
                  </validation-provider >
                </div>
                <div class="group relative w-full mt-2 md:mt-5">
                  <label for="5" class="block w-full pb-1 text-sm font-medium transition-all duration-200 ease-in-out group-focus-within:text-blue-400">Phone</label>
                  <input id="5" type="number" name="phone" v-model="form.phone" class="peer h-10 w-full rounded-md px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:drop-shadow-lg"/>
                </div>
              </div>
              <div class="mt-5 flex">
                <label for="checkbox-1" class="checkbox">
                  <input id="checkbox-1" name="checkbox-group" type="checkbox" v-model="checkbox">
                  <span class="checkbox__mark">
                  <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.13449 12.7269L22.2809 0L25 2.64085L9.13449 18L0 9.15702L2.71912 6.51624L9.13449 12.7269Z" fill="#ffffff"/>
                  </svg>

                  </span>
                </label>
                <span class="text-white text-sm">
                              I agree with the <a class="underline ml-1 mr-1 font-poppins-medium" href="http://ai.hilo.group/terms_conditions.pdf" target="_blank">terms and conditions</a> and
                <a class="underline ml-1 font-poppins-medium" href="http://ai.hilo.group/privacy_policy.pdf" target="_blank">privacy policy</a>
              </span>
              </div>
            </div>
            <div class="modal-footer flex">
              <button @click="submit" :disabled="invalid" :class="[!invalid && checkbox ? 'cursor-pointer' : 'cursor-not-allowed opacity-30']" class="flex contact text-white bg-transparent border-2 border-white h-12 p-3 lg:px-14 text-base font-poppins-regular items-center">
                Submit
              </button>
            </div>
          </div>
        </div>
        </validation-observer>
      </div>
    </div>
  </transition>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
export default {
  name: "ContactModal",
  data() {
    return {
      dialog: false,
      form: {
        first_name: "",
        last_name: "",
        company_name: "",
        company_email: "",
        phone: ""
      },
      checkbox: false,
      loading: false
    }
  },
  components: {ValidationObserver, ValidationProvider},
  methods: {
    submit() {
      const data = {
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        company_name: this.form.company_name,
        company_email: this.form.company_email,
        phone: this.form.phone,
        page: "Hilo AI"
      }
      this.loading = true
      this.$store.dispatch("contact/contactSalesAction", data).then(() => {
        this.$toast("Your information has been submitted!", {
          timeout: 5000,
          position: "top-right",
          hideProgressBar: true,
          type: "info",
          maxToasts: "1",
          closeButton: false,
          toastClassName: "my-custom-toast-class",
        });
        this.loading = false
        this.dialog = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog {
  border-radius: 6px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(170, 170, 170, 0.75);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0px auto;
  width: 80%;
  //padding: 20px 30px;
  //background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  //font-family: Helvetica, Arial, sans-serif;
}

@media only screen and (min-width: 1024px) {
  .modal-container {
    width: 60%;
  }
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.peer {
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 5px !important;
  color: white;
}

label {
  color: white !important;
}
.checkbox {
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  //width: 20rem;
}

.checkbox input {
  display: none;
}

.checkbox input:checked ~ .checkbox__mark svg {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.checkbox__mark {
  margin-right: 0.5rem;
  position: relative;
  width: 1rem;
  height: 1rem;
  //border: 0.2rem solid #000;
  border-radius: 3px;
  background-color: black;
}

.checkbox__mark svg {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  width: 80%;
  height: auto;
  transition: all ease 0.2s;
  opacity: 0;
}

.checkbox--disabled {
  color: grey;
  pointer-events: none;
}

.checkbox--disabled .checkbox__mark {
  border-color: grey;
}

.checkbox--disabled .checkbox__mark {
  border-color: grey;
}
.valid {
  color: red;
}
.error {
  color: red;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
$primary: #c6c0ba;
$secondary: #ffffff;
$third: #000000;
// @ELASTIC BALL
div.elasticball {
  display: flex;
  justify-content: center;
  position: relative;
  height: 6rem;

  &:before {
    content: '';
    width: 2rem;
    height: 2rem;
    bottom: 0;
    opacity: 0;
    border-radius: 50%;
    position: absolute;
    background-color: $secondary;
    transform: scaleY(0.5) translateY(1rem);
    animation: elasticball-bottom .5s .3s infinite;
  }

  &__ball {
    width: 1.5rem;
    height: 1.5rem;
    transform-origin: 50% 100%;
    animation: elasticball .5s infinite cubic-bezier(1,-0.01,0,1);

    i {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 50%;
      background-color: $secondary;

      animation: elasticball-color 2s infinite;
    }
  }
}
@keyframes elasticball {
  0% {
    transform: translateY(0) scaleY(1)
  } 50% {
      transform: translateY(4rem) scaleY(.7)
    } 100% {
        transform: translateY(0) scaleY(1)
      }
}
@keyframes elasticball-color {
  0% {
    background-color: $secondary;
  } 30% {
      background-color: $secondary;
    } 50% {
        background-color: $third;
      } 80% {
          background-color: $third;
        }
}
@keyframes elasticball-bottom {
  0% {
    transform: scale(1, 0.5) translateY(1rem);
    opacity: 0.3;
  } 100% {
      transform: scale(2, 0.5) translateY(1rem);
      opacity: 0;
    }
}
//.close:hover {
//  filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
//}
</style>
