<template>
  <div class="bg-theme-1 px-8 pb-10 lg:pb-32">
    <div class="px-8 lg:px-0 lg:ml-20 pt-10">
      <h1 class="text-white text-4xl font-poppins-medium lg:hidden">Hilo AI Verticals</h1>
      <h1 class="text-white text-4xl font-poppins-medium lg:block hidden">Hilo AI by industries</h1>
    </div>
    <div class="px-8 mt-5 lg:hidden">
      <img src="../assets/images/bilboard.png" alt="bilboard">
    </div>
    <div class="lg:grid lg:grid-cols-8 lg:row-start-1">
      <div class="lg:col-start-1 lg:col-span-4 lg:ml-10">
        <div class="lg:flex lg:w-2/2">
          <div class="border-l-2 lg:border-none border-white	mt-20 mx-8 lg:mx-0 lg:ml-8 lg:mr-4 pl-3 lg:w-1/2">
            <h2 class="text-white text-2xl font-poppins-medium">Hospitality</h2>
            <p class="text-sm text-theme-2 font-poppins-light mt-4 text-left">
              Every room has its own virtual AI reception. They can take care of any need your client can have.
              Only fully private  devices that would not communicate to the outside world.
            </p>
          </div>
          <div class="border-l-2 border-white	mt-20 mx-8 pl-3 lg:ml-0 lg:mr-8 lg:mx-0 lg:w-1/2">
            <h2 class="text-white text-2xl font-poppins-medium">Venues</h2>
            <p class="text-sm text-theme-2 font-poppins-light mt-4 text-left">
              Every venue needs an information stand, and AI can be a 24/7 most-updated assistant in your venue. it can serve as
              a Stand location at an event providing information to the visitors, e.g. gives current agenda or helps get a taxi.
            </p>
          </div>
        </div>
        <div class="lg:flex lg:w-2/2">
          <div class="border-l-2 lg:border-none border-white	mt-20 mx-8 lg:mx-0 lg:ml-8 lg:mr-4 pl-3 lg:w-1/2">
            <h2 class="text-white text-2xl font-poppins-medium">Smart City</h2>
            <p class="text-sm text-theme-2 font-poppins-light mt-4 text-left">
              From an Airport to Central Train Station, from museum to a Monument in the city, AI Avatar can help tourists to find the best information they need in their languages.
            </p>
          </div>
          <div class="border-l-2 border-white	mt-20 mx-8 pl-3 lg:ml-0 lg:mr-8 lg:mx-0 lg:w-1/2">
            <h2 class="text-white text-2xl font-poppins-medium">Retails</h2>
            <p class="text-sm text-theme-2 font-poppins-light mt-4 text-left">
              AI can simply add value to your brand by giving right information in a  right time to your customers.
              In case they need help in store or want to follow up on an issue, the Avatar can assist them.
            </p>
          </div>
        </div>
      </div>
      <div class="hidden lg:block lg:col-start-5 lg:col-span-4 lg:row-start-1 lg:place-self-center lg:mr-20 object-contain">
        <img src="../assets/images/Bus-Stop-Billboard-MockUp.png" alt="bilboard">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SectionThree"
}
</script>
<style lang="scss" scoped></style>
