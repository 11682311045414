import Vue from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import ScrollSpy from "vue2-scrollspy"
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "./vee-validate"
import store from "./store/store"

Vue.use(ScrollSpy)
Vue.use(Toast, {
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(
        t => t.type === toast.type
    ).length !== 0) {
      // Returning false discards the toast
      return false;
    }
    // You can modify the toast if you want
    return toast;
  }
})

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
