<template>
  <div class="bg-theme-1 px-8 pb-10 lg:pb-32">
    <div class="flex justify-evenly py-10 lg:py-14">
      <img class="lg:hidden" src="../assets/logos/NAVANTIA_1.png" alt="NAVANTIA">
      <img class="lg:hidden" src="../assets/logos/Ecoventura-Galapagos-Islands-2018-1.png" alt="Ecoventura">
      <img class="lg:hidden" src="../assets/logos/Isotipo_4s_IN_000.png" alt="Isotipo">
      <img class="lg:hidden" src="../assets/logos/logo-aedcc.png" alt="aedcc">


      <img class="hidden lg:block w-40 h-10 object-contain" src="../assets/logos/NAVANTIA-desktop.png" alt="NAVANTIA">
      <img class="hidden lg:block w-40 h-10 object-contain" src="../assets/logos/Ecoventura-desktop.png" alt="Ecoventura">
      <img class="hidden lg:block w-40 h-10 object-contain" src="../assets/logos/Isotipo_desktop.png" alt="Isotipo">
      <img class="hidden lg:block w-40 h-10 object-contain" src="../assets/logos/desktop-aedcc.png" alt="aedcc">
    </div>
    <div class="px-8 mt-14 lg:mt-10 lg:px-0 lg:ml-20">
      <h1 class="text-white text-4xl font-poppins-medium">Hilo AI <br class="lg:hidden">Deep Tech</h1>
    </div>
    <div class="lg:hidden mt-8 px-8">
      <h2 class="text-white text-2xl font-poppins-medium">Image Processing</h2>
      <p class="text-sm text-theme-2 font-poppins-light mt-3 text-left">
        Our Face Detection technology can seamlessly understand the motions in front of meta human.
        It can detect the passing by people and understand if they have intent to talk to them.
        In both cases, the system can make attention or start a conversation without extra action.
      </p>
    </div>
    <div class="lg:hidden mt-20 px-8">
      <h2 class="text-white text-2xl font-poppins-medium">Generative Adversarial Network</h2>
      <p class="text-sm text-theme-2 font-poppins-light mt-3 text-left">
        This is mix of two sophisticated neural network which makes extreme realistic results.
        This is a real human but not a real human. We create any avatar from any real human which suits your enterprise.
      </p>
    </div>
    <div class="lg:hidden mt-20 px-8">
      <h2 class="text-white text-2xl font-poppins-medium">Natural Language Processing</h2>
      <p class="text-sm text-theme-2 font-poppins-light mt-3 text-left">
        Our Deep Tech NLP will activate the ability to listen and understand the intention of conversations.
        This tech runs fully on our devices and it’s not connected to any cloud services. Hence the performance is accurate and has zero latency. it fully complies with GDPR and HIPPA
      </p>
    </div>
    <div class="lg:hidden mt-20 px-8">
      <h2 class="text-white text-2xl font-poppins-medium">Hilo AI Deep Tech</h2>
      <p class="text-sm text-theme-2 font-poppins-light mt-3 text-left">
        Without name, there is no real human. So as meta human. With our tech, we train any name in real-world environment so
        it will be working with high accuracy in noisy environments. The technology is not recording any conversation or any voice surrounding.
        It simply detects the sound wave signals to identify the wake word and react just like real humans.
      </p>
    </div>
    <TabSection  class="hidden lg:block mt-10"/>
  </div>
</template>
<script>
import TabSection from "@/components/TabSection";
export default {
  name: "SectionTwo",
  components: {TabSection}
}
</script>
<style lang="scss" scoped>

</style>
