<template>
  <div class="bg-theme-1">
    <header>
      <nav>
        <ul>
          <li
              class="list"
              v-for="(tab, tabName) in tabs"
              :key="tabName"
              :class="{'active': tabName === activeTab}"
          >
            <button
                class="tab"
                @mouseover="setTabActive(tabName)"
                :class="{'active': tabName === activeTab}"
            >
              <span class="tab-copy mask relative text-sm font-poppins-regular">{{ tabName }}</span>
            </button>
          </li>
        </ul>
      </nav>
    </header>
    <article>
      <div class="container">
        <div
            v-for="(tabContent, t) in tabs"
            :key="'content'+t"
        >
          <tab-content
              :data="tabContent"
              v-if="t === activeTab"
              inline-template
          >
            <div class="content grid grid-cols-8 gap-x-4 mx-4">
              <!--                <h1 class="text-white">{{data.title}}</h1>-->
              <p class="relative text-white col-start-1 col-span-8 row-start-1 text-lg">{{data.body}}</p>
              <!--                <img class="col-start-1 row-start-1 col-span-2 h-48 w-full object-contain" :src="data.image" alt="">-->
            </div>
          </tab-content>
        </div>
      </div>
    </article>
  </div>
</template>
<script>
// import $ from "jquery"
export default {
  name: "TabSection",
  data() {
    return {
      message: 'Hello',
      tabs: {
        'Image Processing': {
          // image: require('../assets/images/img.png'),
          // title: 'Awesome Title',
          body: 'Our Face Detection technology can seamlessly understand the motions in front of meta human.It can detect the passing by people and understand if they have intent to talk to them. In both cases, the system can make attention or start a conversation without extra action. ',
        },
        'Generative Adversarial Network': {
          // image: require('../assets/images/img_1.png'),
          // title: 'This is great',
          body: 'This is  mix of two sophisticated neural network which makes extreme realistic results. This is a real human but not  a real  human. We create any avatar from any real human which suits your enterprise. '
        },
        'Natural Language Processing': {
          // image: require('../assets/images/img_2.png'),
          // title: 'Look I\'m a title!',
          body: "Our Deep Tech NLP will activate the ability to listen and understand the intention of conversations. This tech runs fully on our devices and it's not connected to any cloud services. Hence the performance is accordant has zero latency. it fully complies with GDPR and HIPPA"
        },
        'Neural Net Training': {
          // image: require('../assets/images/img_3.png'),
          // title: 'LOREM IPSUM?',
          body: 'Without name, there is no real human. So as meta human. With our tech, we train any name in real-world environment so it will be working with high accuracy in noisy environments.  The technology is not recording  any conversation or any voice surrounding. It simply detects the sound wave signals to identify the wake word and react just like real humans. '
        },
      },
      activeTab: 'Image Processing',
    }
  },
  computed:{
    tabContent() {
      return this.tabs[this.activeTab];
    },
  },
  mounted() {
    document.body.onmousemove = (e) => {
      document.documentElement.style.setProperty('--x',(e.clientX)+'px');
      document.documentElement.style.setProperty('--y',(e.clientY)+'px');
    }
  },
  methods: {
    setTabActive(tab) {
      this.activeTab = tab;
    }
  },
  components:{
    'TabContent': {
      props: {
        data: Object,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
::v-deep .mask {
  background:
      radial-gradient(circle 30px
      at var(--x,0) var(--y,0),
          #d72236 99%,white 99%)
      fixed !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  color:transparent !important;
  -webkit-text-fill-color: transparent !important;
  cursor: default;
  //mix-blend-mode: difference;
}
html::before {
  content:"";
  position:fixed;
  width:40px;
  height:40px;
  background: white;
  border-radius:50%;
  top:var(--y,0);
  left:var(--x,0);
  transform:translate(-50%,-50%);
  z-index:2;
}
body {
  font-family: 'PT Sans', sans-serif;
  cursor: none;
}

header {
  width: 100%;
  display:flex;
  align-items:flex-end;
  overflow: hidden;
  nav {
    width:100%;
    display:flex;
    justify-content:center;
    ul {
      display:flex;
      flex-direction:row;
    }
    .list::before {
      width: 100%;
      margin-left: 1rem;
      border-top: 3px solid  #D9D9D9;
    }
    .list {
      border-top: 3px solid #D9D9D9;
      width: 100%;
      margin-left: 1rem;
      &.active {
        border-top: 5px solid white;
      }
    }
    .tab::before {
      content: "";
      height: 2px;
      width: 100%;
      margin-bottom: 1rem;
      background-color: #919191;
      transition: background-color 0.3s;
    }
    .tab {
      //border-top: 3px solid #D9D9D9;
      $border-radius: 8px;
      $transition-duration:.24s;
      $transition-timing: cubic-bezier(0.86, 0, 0.07, 1);
      font-family: 'PT Sans', sans-serif;
      font-weight: bold;
      font-size: 20px;
      color: #D9D9D9;
      //padding: 5px 40px;
      //margin:0 24px 0;
      padding-right: 50px;
      text-align: left;
      outline:none;
      cursor:pointer;
      position: relative;
      background:none;
      //&:hover {
      //  .tab {
      //    &-background {
      //      height:16px;
      //    }
      //    &-rounding {
      //      transform: scaleY(1);
      //    }
      //  }
      //
      //}
      &.active {
        //border-top: 3px solid white;
        color: white;
        //.tab {
        //  &-background {
        //    height:100%;
        //  }
        //  &-rounding {
        //    transform: scaleY(1);
        //  }
        //}
        span {
          font-weight: 500;
        }
      }
      &-copy {
        position: relative;
        z-index:1;
      }
      //&-background {
      //  display: block;
      //  position: absolute;
      //  bottom: 0;
      //  left: 0;
      //  right: 0;
      //  background: #fff;
      //  z-index:0;
      //  height:0;
      //  border-radius: $border-radius $border-radius 0 0;
      //  transition: height $transition-timing $transition-duration;
      //}
      //&-rounding {
      //  $rounding-size: $border-radius;
      //  $background-color: #fff;
      //  //padding:$rounding-size / 2;
      //  padding: $rounding-size / 2 $rounding-size;
      //  display: block;
      //  position:absolute;
      //  overflow:hidden;
      //  bottom: 0;
      //  transform: scaleY(0);
      //  transform-origin: bottom;
      //  transition: transform $transition-timing $transition-duration;
      //  &:before {
      //    content:"";
      //    position: absolute;
      //    width: $rounding-size * 2;
      //    height: $rounding-size * 2;
      //    top:-$rounding-size ;
      //    left:-$rounding-size ;
      //    border-radius:100%;
      //  }
      //  &.left {
      //    left: -$rounding-size ;
      //    &:before {
      //      box-shadow:0 0 0 10rem $background-color;
      //    }
      //  }
      //  &.right {
      //    right: -$rounding-size ;
      //    &:before {
      //      left:$rounding-size;
      //      box-shadow:0 0 0 10rem $background-color;
      //    }
      //  }
      //}

    }
  }
}
article {
  .container {
    widht:100%;
    padding: 40px;
    max-width: 1024px;
    box-sizing:border-box;
    margin: 0 auto;
    height:200px;
  }
  h1 {
    font-weight: bold;
    font-size: 48px;
    margin-bottom: 30px;;
  }
  p {
    line-height: 1.6;
    font-size: 19px;
    color: rgba(#000,.7);
    margin-bottom: 20px;
  }
}

.fade-enter-active,
.fade-leave-active {
  & > * {
    transition-duration: 200ms;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(.6,.15,.35,.8);
  }

}

$delay: 100ms;
$delayStep: 100ms;

.fade-enter,
.fade-leave-to {
  & > * {
    opacity: 0;
    transform: translateY(40px);
  }
}
.fade-enter-active {
  & > * {
    &:nth-child(2) {
      transition-delay: $delay;
    }
    &:nth-child(3) {
      transition-delay: $delay + $delayStep;
    }
  }
}
.fade-leave-active {
  & > * {
    &:nth-child(1) {
      transition-delay: $delay + $delayStep;
    }
    &:nth-child(2) {
      transition-delay: $delay;
    }
  }
}
@media only screen and (min-width: 1279px) {
  .tab {
    white-space: nowrap;
  }
}
</style>
