<template>
  <div class="px-8 pb-20 lg:pb-32 bg-black lg:mx-0 lg:px-28">
    <div class="pt-10 lg:pt-20">
      <h1 class="text-white text-4xl font-poppins-medium lg:font-poppins-medium text-center lg:text-left">Proven results in weeks,
        <br class="hidden lg:block">not years</h1>
    </div>
    <div class="mt-8 grid grid-cols-8 lg:hidden">
      <div class="bg-theme-1 border-b-2 border-white h-16 cols-start-1 col-span-6 row-start-1 capitalize">
        <span class="rec-1-span text-sm text-white ml-4">Exec. <br><span class="text-sm text-white">Briefing</span></span>
      </div>
      <div class="col-start-7 border-b-2 border-white col-span-2 row-start-1 text-right capitalize">
        <span class="rec-1-span mr-4 text-sm text-white text-right">4 <br>hours</span>
      </div>
      <div class="bg-theme-5 h-24 border-b-2 border-white cols-start-1 col-span-6 row-start-2 capitalize">
        <span class="rec-2-span text-sm text-white ml-4 mt-4">Technology <br><span class="text-sm text-white">Assessment</span></span>
      </div>
      <div class="col-start-7 border-b-2 border-white col-span-2 row-start-2 text-right capitalize">
        <span class="rec-2-span mr-4 text-sm text-white text-right mt-4">4-5 <br>days</span>
      </div>
      <div class="bg-theme-6 border-b-2 border-white h-32 cols-start-1 col-span-6 row-start-3 capitalize">
        <span class="rec-2-span text-sm text-white ml-4 mt-4">Production <br><span class="text-sm text-white">Trial</span></span>
      </div>
      <div class="col-start-7 border-b-2 border-white col-span-2 row-start-3 text-right capitalize">
        <span class="rec-2-span mr-4 text-sm text-white text-right mt-4">4-9 <br>weeks</span>
      </div>
      <div class="bg-theme-7 h-40 cols-start-1 col-span-6 row-start-4 capitalize">
        <span class="rec-2-span text-sm text-white ml-4 mt-4">AI Application <br><span class="text-sm text-white">Deployment in Production</span></span>
      </div>
      <div class="col-start-7 col-span-2 row-start-4 text-right capitalize">
        <span class="rec-2-span mr-4 text-sm text-white text-right mt-4">2-4 <br>months</span>
      </div>
    </div>
    <div class="hidden lg:block mt-8 lg:grid lg:grid-cols-10">
      <div class="col-start-1 col-span-1 row-start-1 border-r border-white">
        <div class="bg-theme-1 h-32 cols-start-1 col-span-6 row-start-1 capitalize pt-3">
          <span class="text-sm text-white ml-4">Exec. <br><span class="text-sm text-white ml-4">Briefing</span></span>
        </div>
        <div class="col-start-7 col-span-2 row-start-1 text-left capitalize my-1">
          <span class="text-sm text-white text-left ml-4 capitalize">4 hours</span>
        </div>
      </div>

      <div class="col-start-2 col-span-2 row-start-1 border-r border-white">
        <div class="bg-theme-5 h-32 cols-start-1 col-span-6 row-start-1 capitalize pt-3">
          <span class="text-sm text-white ml-4">Technology <br><span class="text-sm text-white ml-4">Assessment</span></span>
        </div>
        <div class="col-start-7 col-span-2 row-start-1 text-left capitalize my-1">
          <span class="text-sm text-white text-left ml-4 capitalize">4-5 days</span>
        </div>
      </div>

      <div class="col-start-4 col-span-3 row-start-1 border-r border-white">
        <div class="bg-theme-6 h-32 cols-start-1 col-span-6 row-start-1 capitalize pt-3">
          <span class="text-sm text-white ml-4">Production <br><span class="text-sm text-white ml-4">Trial</span></span>
        </div>
        <div class="col-start-7 col-span-2 row-start-1 text-left capitalize my-1">
          <span class="text-sm text-white text-left ml-4 capitalize">4-9 weeks</span>
        </div>
      </div>

      <div class="col-start-7 col-span-4 row-start-1">
        <div class="bg-theme-7 h-32 cols-start-1 col-span-6 row-start-1 capitalize pt-3">
          <span class="text-sm text-white ml-4">AI Application <br><span class="text-sm text-white ml-4">Deployment in Production</span></span>
        </div>
        <div class="col-start-7 col-span-2 row-start-1 text-left capitalize my-1">
          <span class="text-sm text-white text-left ml-4 capitalize">2-4 months</span>
        </div>
      </div>
    </div>
    <div class="mt-10 lg:mt-20">
      <h1 class="text-white text-4xl font-poppins-medium">The Next Step</h1>
    </div>
    <div class="mt-6 lg:flex">
      <div>
        <button @click="showDialog" class="flex contact text-white bg-transparent border-4 border-theme-1 h-16 p-3 lg:px-8 text-xl font-poppins-medium items-center whitespace-nowrap">
          Contact Sales
          <svg class="w-6 h-9 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><g id="Right-2" data-name="Right"><polygon points="17.5 5.999 16.793 6.706 22.086 11.999 1 11.999 1 12.999 22.086 12.999 16.792 18.294 17.499 19.001 24 12.499 17.5 5.999" style="fill:#ffffff"/></g></svg>
        </button>
      </div>
      <div class="mt-6 lg:mt-0 lg:ml-6 lg:h-full lg:pb-4 lg:mt-auto">
        <p class="text-sm lg:text-lg font-poppins-light text-white w-2/3">
          Learn how our industry-leading
          Enterprise AI software products can
          help your organization.
        </p>
      </div>
    </div>
    <ContactModal ref="dialog"/>
  </div>
</template>
<script>
import ContactModal from "@/components/ContactModal";
export default {
  name: "SectionFour",
  components: {ContactModal},
  methods: {
    showDialog() {
      this.$refs.dialog.dialog = true
    }
  }
}
</script>
<style lang="scss" scoped>
.rec-1-span {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.rec-2-span {
  height: 100%;
  display: flex;
  flex-direction: column;
}

</style>
