<template>
  <div class="main lg:flex lg:w-3/3 z-20">
<!--    <img src="../assets/backgrounds/mobile.png" class="mobile-bg absolute top-0 -z-1 w-full h-full" alt="">-->
    <div class="lg:w-2/3 z-10">
      <h1 class="main-title text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl font-poppins-medium lg:font-poppins-black text-white mx-8 pt-8 lg:mx-0 lg:ml-20">This is A Digital Human</h1>
      <div class="container flex mt-2 lg:mt-3 mx-8 lg:mx-0 lg:ml-20 text-white text-xl lg:text-3xl text-white font-poppins-regular">who can
<!--        <span class="text-lg lg:text-2xl text-white font-poppins-regular">who can listen, understand, talk</span>-->
          <h2 class="quotes text-xl lg:text-3xl text-white font-poppins-regular ml-2">listen</h2>
          <h2 class="quotes text-xl lg:text-3xl text-white font-poppins-regular ml-2">understand</h2>
          <h2 class="quotes text-xl lg:text-3xl text-white font-poppins-regular ml-2">talk</h2>
        <h2 class="quotes text-xl lg:text-3xl text-white font-poppins-regular ml-2">see</h2>
      </div>
      <div class="mt-8 mx-8 lg:mt-10 lg:mx-0 lg:ml-20 lg:w-2/3">
        <p class="font-poppins-regular text-theme-2 text-lg">Hilo AI can deliver enterprise-grade conversational AI development for your Industry.</p>
      </div>
    </div>
    <!--    <img src="../assets/images/avatar.png" alt="avatar" class="mt-3 w-full">-->
    <div class="video mt-3 w-full lg:w-1/3 relative courser-pointer">
      <div class="relative" @click="checkMuteUnmute">
        <video autoplay :muted="muteVideo" loop ref="videoRef" id="vid-mobile" class="w-full">
          <source src="../assets/video/hilo2.mp4" type="video/mp4">
        </video>
      </div>
      <div class="mute absolute left-2 bottom-2" @click="checkMuteUnmute">
        <a class="text-white" v-if="this.muteVideo">
          <svg class="h-5 w-5" fill="#fffff1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
               width="52px" height="52px" viewBox="0 0 52 52" enable-background="new 0 0 52 52" xml:space="preserve">
          <path d="M48.5,5.6l-2.1-2.1C45.8,2.9,44.7,3,44,3.8l-10,10V9.9C34,5.5,30.5,2,26.1,2h-0.2C21.5,2,18,5.5,18,9.9v14.5
        c0,1.5,0.4,3,1.2,4.2l-3.5,3.5C14,30,13,27.3,13,24.4v-4c0-1.4-1.1-2.4-2.5-2.4C9.1,18,8,19,8,20.4v4c0,4.3,1.6,8.2,4.2,11.2L3.8,44
        c-0.7,0.7-0.8,1.8-0.3,2.4l2.1,2.1C6.2,49.1,7.3,49,8,48.2L48.2,8C49,7.3,49.1,6.2,48.5,5.6z"/>
            <path d="M39,23.2v1.2c0,7-5.8,12.8-13,12.8c-0.3,0-0.6,0-0.9,0l-3.9,4.1c0.8,0.2,1.8,0.4,2.8,0.6V45h-4.5
        c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5h13.1c1.4,0,2.5-1.1,2.5-2.5S33.9,45,32.5,45H28v-3.2c9-1.2,16-8.6,16-17.4v-4
        c0-0.6-0.3-1.2-0.6-1.6L39,23.2z"/>
      </svg>
        </a>

        <a class="text-white" v-if="!this.muteVideo">
                  <svg class="h-5 w-5" fill="#fffff1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                       width="52px" height="52px" viewBox="0 0 52 52" enable-background="new 0 0 52 52" xml:space="preserve">
        <g>
          <path d="M41.5,18c-1.4,0-2.5,1-2.5,2.4v4c0,7-5.9,12.8-13.1,12.8s-13.1-5.8-13.1-12.8v-4c0-1.4-1.1-2.4-2.5-2.4
            S8,19,8,20.4v4c0,8.9,6.8,16.2,15.5,17.4v3.4h-4.1c-1.4,0-2.5,1-2.5,2.4c0,1.4,1.1,2.4,2.5,2.4h13.1c1.4,0,2.5-1,2.5-2.4
            c0-1.4-1.1-2.4-2.5-2.4h-4.1v-3.4C37.2,40.6,44,33.3,44,24.4v-4C44,19,42.9,18,41.5,18z"/>
          <path d="M26,32.4c4.4,0,8-3.6,8-8V9.9C34,5.5,30.5,2,26.1,2h-0.2C21.5,2,18,5.5,18,9.9v14.5
            C18,28.8,21.6,32.4,26,32.4z"/>
        </g>
        </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'SectionOne',
  data() {
    return {
      muteVideo: true
    }
  },
  mounted() {
    (function() {

      let quotes = $(".quotes");
      let quoteIndex = -1;

      function showNextQuote() {
        ++quoteIndex;
        quotes.eq(quoteIndex % quotes.length)
            .fadeIn(500)
            .delay(500)
            .fadeOut(500, showNextQuote);
      }

      showNextQuote();

    })();
  },
  methods: {
    checkMuteUnmute() {
        this.muteVideo = !this.muteVideo
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  #vid-mobile {
    width: 100%;
    background: black;
    height: 400px;
  }
}

@media only screen and (min-width: 1024px) {
  #vid-mobile {
    background: black;
    height: 400px;
    //margin-top: -5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .mute {
    display: none;
  }
  .video:hover {
    & .mute {
      display: block !important;
      position: absolute !important;
      left: 2px !important;
      bottom: 10px !important;
    }
  }
}
.video {
  z-index: 29;
}
@media only screen and (min-width: 1023px) {
  .main-title {
      font-weight: 700;
  }
}
.word {
  display: inline-block;
  width: auto;
  height: 2em;
  overflow: hidden;
  position: absolute;
  top: -1em;
  padding: 1em 0 0;
}

.rotate {
  display: inline-block;
  // overflow: hidden;
  height: 1em;
  font-weight: bold;
  line-height: 1em;
  vertical-align: bottom;
  position: relative;
  width: auto;
  ul {
    display: inline-block;
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      display: block;
      clear: both;
      margin: 0 0 1em;
      padding: 0;
      text-align: left;
    }
  }
}
.quotes {display: none;}
</style>
