<template>
  <div id="app">
    <Navbar />
    <section class="main" v-scroll-spy>
      <SectionOne id="sectionOne" />
      <section-two id="sectionTwo"/>
      <section-three id="sectionThree"/>
      <SectionFour id="contact"/>
    </section>
    <Footer />
  </div>
</template>

<script>
import SectionOne from './components/SectionOne.vue'
import Navbar from "@/components/Navbar";
import SectionTwo from "@/components/SectionTwo";
import SectionThree from "@/components/SectionThree";
import SectionFour from "@/components/SectionFour";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  components: {
    Footer,
    SectionThree,
    SectionTwo,
    Navbar,
    SectionOne,
    SectionFour
  }
}
</script>

<style scoped>
#app {
  overflow: hidden;
  background-color: #00000a;
  background-image: url("assets/images/main.png");
  background-size: cover;
  /*height: 100vh;*/
  background-repeat: no-repeat;
  /*background-attachment: fixed;*/
}
@media only screen and (min-width: 768px) {
  #app {
    background-color: #000000;
    background-image: url("assets/images/main-desktop.png");
    background-size: contain;
    /*height: 100vh;*/
    background-repeat: no-repeat;
    /*background-attachment: fixed;*/
  }
}
.mobile-main {
  width: 100%;
}
</style>
